import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiDescriptionList,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { CompanyContactNumber, RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"
import { phoneFormat } from '../../components/utils';

const ConfirmRescheduledPage = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { facilityStore, bookingStore, customerStore } = useStore()
  const { facility } = facilityStore
  const { booking, rescheduleBooking, bookingResult, currentBooking } =
    bookingStore
  const [isBookingOperationProcessing, setBookingOperationProcessing] =
    useState(false)

  const data = {
    title: "Confirmation",
    primaryContent: "Confirm booking",
    secondaryContent: "Back",
  }

  const handlePrimaryClick = async () => {
    setBookingOperationProcessing(true)

    await rescheduleBooking()

    navigate("/book/reschedule-completed")
  }

  const handleSecondaryClick = () => {
    navigate("/book/reschedule-booking")
  }

  useEffect(() => {
    if (bookingResult && currentBooking && booking) {
      let staleActiveBookingInMemory,
        staleBookingSelectionScreen: boolean = false

      staleActiveBookingInMemory = bookingResult.id !== currentBooking.bookingId

      staleBookingSelectionScreen =
        booking.repairType === currentBooking.repairType &&
        booking.repairDate === currentBooking.repairDate &&
        booking.dropOffTime === currentBooking.dropOffTime

      if (staleActiveBookingInMemory || staleBookingSelectionScreen) {
        alert(
          "We noticed you have updated your booking. \nWe are redirecting you to the updated booking."
        )
        navigate(bookingResult.manageBookingUrlRelative)
        return
      }
    }
  }, [])

  useEffect(() => {

    const contactNumber = facility?.contactDetails !== '' ? facility?.contactDetails as string: CompanyContactNumber;

    if (booking) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === booking.repairType
      )

      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.CarRepair} alt="Repair Type" />,
            description: (
              <EuiText grow={false}>
                Repair required
                <br />
                <strong>{repairType.name}</strong>
                <br />
                <p className="highlightedText">
                  {repairType.durationDescription}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Clock} alt="Drop Off" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Drop off</strong>
                  <br />
                  {`${getBookingDateTimeUIFormatted(
                    booking?.repairDate,
                    booking?.dropOffTime
                  )}`}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CalendarIcon} alt="Completion" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Estimated completion</strong>
                  <br />
                  {getCompletionDateUIFormatted(
                    booking?.repairDate,
                    repairType.durationInWeeks
                  )}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Phone} alt="Contact" style={{width: '42px'}} />,
            description: <EuiText grow={false}>
                <p><strong>Assistance</strong><br />
                For booking assistance call <br/><a href={'tel:' + contactNumber.replace(/ /g, '')}>{phoneFormat(contactNumber)}</a></p>
            </EuiText>
        },
        ]

        setBookingSummary([...summary])
      }
    }
  }, [])

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={85}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={
        <HailCXDoubleButtonFooter
          primaryContent={data.primaryContent}
          secondaryContent={data.secondaryContent}
          handlePrimaryClick={handlePrimaryClick}
          handleSecondaryClick={handleSecondaryClick}
          secondaryIconRequired={true}
          primaryButtonDisabled={isBookingOperationProcessing}
        />
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1>Booking selected</h1>
        <p>Happy with your new booking? Let's lock it in.</p>
      </EuiText>
      <EuiSpacer />
      <EuiPanel>
        <EuiDescriptionList type="column" listItems={bookingSummary} />
      </EuiPanel>
      <HailCXRepairTypeAlert />
    </HailCXLayout>
  )
}

export default observer(ConfirmRescheduledPage)
